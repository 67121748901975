import About from './components/About';
import Home from './components/Home';
import Banner from './components/Banners';
import Services from './components/Services';
import Statistics from './components/Statistics';
import Clients from './components/Clients';
import Contact from './components/Contact';
import CopyRights from './components/CopyRights';

function App() {
  return (
    <main>
      <Home />
      <About />
      <Banner />
      <Services />
      <Statistics />
      <Clients />
      <Contact />
      <CopyRights />
    </main>
  );
}

export default App;
