import styled from "styled-components";

export const ServicesSection = styled.section`
    box-sizing: border-box;
    min-height: 100vh;
    padding: 100px;
    text-align: center;
`;

export const TitleHeading = styled.h3`
    margin-bottom: 8px;
    span {
        color: var(--secondary);
        font-family: inherit;
        font-size: calc(var(--section-heading) + 10px);
        font-weight: 800;
    }
`;

export const SubTitle = styled.p`
    color: var(--mindcolor);
    font-size: calc(var(--larger-size) + 4px);
    font-weight: 300;
    line-height: 40px;
`;

export const ServicesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
`;

export const ServicesCards = styled.div`
    align-items: center;
    border: 1px solid var(--light);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    min-height: 240px;
    max-width: 25%;
    min-width: 25%;
    padding: 20px;
    transition: all .3s ease-in-out;
    &.border-top {
        border-top: none !important;
    }
    &.border-bottom {
        border-bottom: none !important;
    }
    &.border-left {
        border-left: none;
    }
    &.border-right {
        border-right: none;
    }
    &.border-left-top {
        border-top: none;
        border-left: none;
    }
    &.border-left-bottom {
        border-bottom: none;
        border-left: none;
    }
    &.border-right-top {
        border-top: none;
        border-right: none;
    }
    &.border-right-bottom {
        border-bottom: none;
        border-right: none;
    }
    &:hover {
        background-color: var(--primary);
        * {
            color: var(--basic);
        }
    }
    &:nth-of-type(even):hover {
        background-color: var(--secondary);
    }
`;

export const CardContent = styled.div``;

export const ServicesIcon = styled.div`
    margin-bottom: 32px;
    svg {
        font-size: var(--section-heading);
    }
`;

export const ServicesTitle = styled.h6`

`;
