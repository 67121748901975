import React from 'react';

import Carousel from 'react-bootstrap/Carousel';
import { PiQuotesFill } from "react-icons/pi";

import { feedback } from './feedback';

import { 
	ClientsSection,
	ClientsWrapper,
	ContentSection,
	ContentDiv,
	TitleHeading,
	Paragraph,
	TestinomialSection,
	ImageDiv,
	SectionHeading,
	CarouselDiv,
	Quotes,
	UserImg,
	Image,
	Feedback,
	Title,
	Subtitle,
} from './styled';

const Clients = () => {
  	return (
    	<ClientsSection id="clients">
			<ClientsWrapper>
				<ContentSection>
					<ContentDiv>
						<TitleHeading>Our <span>Clients</span></TitleHeading>
						<Paragraph>
							We are proud to offer our services to a wide clientele ranging from Insurance, Healthcare, Government and Retail.
							<br /><br />
							We are a MS Network Partner and Vendor to Government.
						</Paragraph>
					</ContentDiv>
				</ContentSection>
				<TestinomialSection>
					<ContentDiv className='testinomial-content'>
						<SectionHeading>Our some happy clients feedback!</SectionHeading>
						<CarouselDiv>
							<Quotes>
								<PiQuotesFill />
							</Quotes>
							<Carousel>
							{
								feedback.map( (list) => (
									<Carousel.Item key={list.id}>
									<UserImg>
										<Image src={list.image} alt='Client Image' />
									</UserImg>
									<Feedback>
										{list.feedback}
									</Feedback>
									<Title>{list.user}</Title>
									<Subtitle>{list.designation}.</Subtitle>
								</Carousel.Item>
								))
							}
							</Carousel>
							<Quotes>
								<PiQuotesFill />
							</Quotes>
						</CarouselDiv>
						<ImageDiv />
					</ContentDiv>
				</TestinomialSection>
			</ClientsWrapper>
    	</ClientsSection>
  	)
}

export default Clients;