import styled from "styled-components";

import clientbg from "./../../images/illustration5.png";

export const ClientsSection = styled.section`
    padding: 100px 0;
`;

export const ClientsWrapper = styled.div`
    align-items: center;
    display: flex;
`;

export const ContentSection = styled.div`
    flex: 1;
`;

export const ContentDiv = styled.div`
    padding-left: 100px;
`;

export const TitleHeading = styled.h3`
    margin-bottom: 32px;
    width: 240px;
    span {
        color: var(--secondary);
        font-family: inherit;
        font-size: calc(var(--section-heading) + 10px);
        font-weight: 800;
    }
`;

export const Paragraph = styled.p`
    color: var(--mindcolor);
    font-size: var(--large-size);
    letter-spacing: .5px;
    text-align: justify;
`;

export const TestinomialSection = styled.div`
    align-items: flex-start;
    display: flex;
    flex: 3;
    min-height: 700px;
    overflow: hidden;
    position: relative;
    .testinomial-content {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        justify-content: center;
        padding-left: 0;
    }
    .carousel {
        .carousel-indicators {
            margin-bottom: -2rem;
            button {
                background-color: var(--secondary);
            }
        }
        .carousel-control-next, 
        .carousel-control-prev {
            background-color: var(--default);
            bottom: unset;
            color: var(--default);
            height: 40px;
            left: unset;
            opacity: 1;
            right: unset;
            top: -30px;
            width: 40px;
            &:hover {
                background-color: var(--secondary);
            }
        }
        .carousel-control-next {
            right: 0;
        }
        .carousel-control-prev {
            right: 55px;
        }
    }
`;

export const ImageDiv = styled.div`
    background: url(${clientbg}) no-repeat bottom right;
    background-size: contain;
    bottom: 0;
    height: 100%;
    opacity: .4;
    position: absolute;
    right: 0;
    width: 500px;
`;

export const SectionHeading = styled.h4`
    color: var(--default);
    text-transform: capitalize;
    font-weight: 400;
    margin-bottom: 60px;
    min-width: 100%;
    max-width: 100%;
    text-align: center;
`;

export const CarouselDiv = styled.div`
    min-width: 700px;
    max-width: 700px;
    padding: 60px 0;
    position: relative;
    z-index: 999;
`;

export const Quotes = styled.h1`
    position: absolute;
    &:nth-of-type(1) {
        transform: rotateZ(180deg);
        top: 0;
        left: 0;
    }
    &:nth-of-type(2) {
        bottom: 85px;
        right: 85px;
    }
    svg {
        font-size: inherit;
        path {
            color: var(--secondary);
        }
    }
`;

export const UserImg = styled.div`
    align-items: center;
    background-color: var(--light);
    border-radius: 50%;
    border: 2px solid var(--default);
    display: flex;
    height: 100px;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    width: 100px;
`;

export const Image = styled.img`
    height: 90px;
    object-fit: contain;
    width: 90px;
`;

export const Feedback = styled.p`
    color: var(--mindcolor);
    font-size: var(--larger-size);
    font-weight: 300;
    line-height: 32px;
    margin: 24px 0;
    text-align: center;
`;

export const Title = styled.h6`
    color: var(--dark);
    margin-top: 40px;
    text-transform: uppercase;
`;

export const Subtitle = styled.h6`
    font-size: var(--large-size);
    font-weight: 700;
`;
