import React from 'react';

import { IoIosArrowRoundForward } from 'react-icons/io';

import Navbar from './../Navbar';
import Buttons from './../Buttons';

import {
	HomeSection,
	HeroSection,
	Container,
	MainHeading,
	TextOne,
	TextTwo,
	SubTitle,
	IntroSection,
	IntroWrapper,
	ContentSection,
	ContentDiv,
	TitleHeading,
	Paragraph,
	ImageSection,
} from './styled';

const Home = () => {
	
  	return (
		<HomeSection>
			<Navbar />
			<HeroSection id='home'>
				<Container>
					<MainHeading>
						<TextOne className="">Insta</TextOne>
						<TextTwo className="animate">Objects</TextTwo>
					</MainHeading>
					<SubTitle>We are a Microsoft Partner IT Consulting firm based in Toronto, Canada.</SubTitle>
					<Buttons 
						target="#introduction"
						title="Get Started"
						icon={<IoIosArrowRoundForward />}
					/>
				</Container>
			</HeroSection>
			<IntroSection id="introduction">
				<IntroWrapper>
					<ContentSection>
						<ContentDiv>
							<TitleHeading>Our Main <span>Features</span></TitleHeading>
							<Paragraph>
								Our objective is to do Custom Application development, provide IT solutions and Services to both small and large companies.
								<br /><br />
								Working closely with your team, we quickly identify your business needs and turn them into solutions and profits. 
								Our resources are available to work on-site or off-site.
							</Paragraph>
						</ContentDiv>
					</ContentSection>
					<ImageSection />
				</IntroWrapper>
			</IntroSection>
		</HomeSection>
  	)
}

export default Home;