import styled from "styled-components";

import backgroundImg from './../../images/backgrounds/hero-bg1.jpg';
import introBg from './../../images/illustration3.png';

export const HomeSection = styled.section`
    box-sizing: border-box;
    min-height: 100vh;
    position: relative;
`;

export const HeroSection = styled.div`
    align-items: center;
    background: url(${backgroundImg}) no-repeat center;
    background-size: cover;
    display: flex;
    justify-content: center;
    min-height: 100vh;
`;

export const Container = styled.div`
    text-align: center;
`;

export const MainHeading = styled.h1`
    line-height: 150px;
    position: relative;
    text-align: center;
    font-weight: 700;
    * {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
    }
`;

export const TextOne = styled.span`
    color: var(--basic);
`;

export const TextTwo = styled.span`
    color: var(--secondary);
    font-family: 'Bungee Outline', sans-serif;
`;

export const SubTitle = styled.h4`
    font-weight: 300;
    margin-bottom: 32px;
`;

export const IntroSection = styled.div`
    box-sizing: border-box;
    padding: 80px 0;
`;

export const IntroWrapper = styled.div`
    align-items: center;
    display: flex;
`;

export const ContentSection = styled.div`
    flex: 2;
    padding: 0 100px;
`;

export const ContentDiv = styled.div`
    width: 480px;
`;

export const TitleHeading = styled.h3`
    margin-bottom: 32px;
    span {
        color: var(--secondary);
        font-family: inherit;
        font-size: calc(var(--section-heading) + 10px);
        font-weight: 800;
    }
`;

export const Paragraph = styled.p`
    color: var(--mindcolor);
    text-align: justify;
`;

export const ImageSection = styled.div`
    background: url(${introBg}) no-repeat;
    background-size: contain;
    border-radius: 12px;
    flex: 3;
    min-height: 750px;
    position: relative;
    opacity: .8;
`;
