import { GiBullseye } from "react-icons/gi";
import { FaRegLightbulb, FaChartLine } from "react-icons/fa";

export const data = [
    {
        id: 1,
        title: "Mission",
        details: 'We aim to be the "go to" IT development and consulting company for both Government and non Government companies. We bring knowledge, expertise and dedication to help with your IT needs.',
        icon: <GiBullseye />
    },
    {
        id: 2,
        title: "Vision",
        details: 'Increase in the number of IT service providers has only led to finding the "right" candidate more difficult. You want someone who can deliver results as well as fit right into a position.',
        icon: <FaRegLightbulb />
    },
    {
        id: 3,
        title: "Strategy",
        details: 'At InstaObjects, we have over 15 years of experience from working in a variety of IT environments for different companies. Each company that we serve - we make a commitment to understand its needs and help it achieve its goals.',
        icon: <FaChartLine />
    },
    {
        id: 4,
        title: "",
        details: "When you choose to work with us, you will be working with a company which believes in teamwork, professionalism and integrity. We are passionate about Technology and consider no job too small to work upon.",
        icon: ""
    }
]