import React from 'react';

import { LiaCopyrightSolid } from "react-icons/lia";


import { 
	CopyRightSection,
	Paragraph, 
} from './styled';

const CopyRights = () => {
  	return (
		<CopyRightSection>
			<Paragraph>
				Copyrights <LiaCopyrightSolid /> 2024. | <span>InstaObjects</span>. All Rights Reserved. 
			</Paragraph>
		</CopyRightSection>
  	)
}

export default CopyRights;