import styled from "styled-components";

export const CopyRightSection = styled.div`
    background-color: var(--default);
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Paragraph = styled.p`
    color: var(--basic);
    font-weight: 300;
    span {
        color: var(--secondary);
        text-shadow: .5px .5px var(--dark);
        font-weight: 500;
    }
    svg path {
        color: var(--basic);
    }
`;