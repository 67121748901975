import React from 'react';

import logo from './../../images/logo.jpg';

import {
	NavbarSection,
	NavbarLogo,
	LogoImg,
	LogoTitle,
	NavbarMenu,
	StyledUl,
	StyledLi,
	Link,
} from './styled';

const Navbar = () => {
  return (
    <NavbarSection>
        <NavbarLogo>
            <LogoImg src={logo} alt="InstaObjects" />
            <LogoTitle>Insta<span>Objects</span></LogoTitle>
        </NavbarLogo>
        <NavbarMenu>
            <StyledUl>
                <StyledLi>
                    <Link href="#home" className='active'>Home</Link>
                </StyledLi>
                <StyledLi>
                    <Link href="#about">About</Link>
                </StyledLi>
                <StyledLi>
                    <Link href="#services">Services</Link>
                </StyledLi>
                <StyledLi>
                    <Link href="#clients">Clients</Link>
                </StyledLi>
                <StyledLi>
                    <Link href="#contact">Contact Us</Link>
                </StyledLi>
            </StyledUl>
        </NavbarMenu>
    </NavbarSection>
  )
}

export default Navbar;