import styled from "styled-components";

import statistics from './../../images/backgrounds/statistics.jpg';

export const StatisticsSection = styled.section`
    background: url(${statistics}) no-repeat center;
    background-size: cover;
    background-attachment: fixed;
    min-height: 450px;
    position: relative;
`;

export const StatisticsWrapper = styled.div`
    background-color: rgba(188, 138, 79, .5);
    box-sizing: border-box;
    height: 100%;
    left: 0;
    padding: 60px 100px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
`;

export const ContentDiv = styled.div`
    text-align: center;
`;

export const TitleHeading = styled.h2`
	align-items: baseline;
    color: var(--basic);
    display: flex;
	gap: 24px;
    justify-content: center;
    line-height: 54px;
    margin-bottom: 54px;
    text-shadow: 3px 3px var(--default);
    span {
        color: var(--secondary);
        font-family: inherit;
        font-size: calc(var(--section-heading) + 6px);
	    font-weight: inherit;
        text-shadow: 2px 2px var(--dark);
    }
`;

export const StatisticsSets = styled.div`
    display: flex;
`;

export const StatesDiv = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 24px 0;
    &:nth-child(2) {
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
    }
`;

export const MainFigure = styled.h2`
    margin: 0;
    span {
        color: inherit;
        font-family: 'Bungee Outline', sans-serif;
        font-size: inherit;
        font-weight: inherit;
        text-shadow: 2px 2px var(--dark);
        line-height: 120px;
    }
`;

export const SubTitle = styled.h5`
    background-color: var(--default);
    color: var(--basic);
    font-weight: 300;
    padding: 4px 12px;
    text-align: center;
    span {
        background-color: var(--secondary);
        border-radius: 5px;
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        font-weight: 600;
        display: inline-block;
        padding: 0 8px;
        text-shadow: 1px 1px var(--dark);
    }
`;
