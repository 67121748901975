import React from 'react';

import logo from './../../images/logo.jpg';

import { GrMap } from "react-icons/gr";
import { FiPhoneCall } from "react-icons/fi";
import { RiMailSendLine } from "react-icons/ri";
import { IoIosArrowRoundForward } from "react-icons/io";
import { FaLinkedin, FaFacebookSquare, FaGooglePlusSquare } from "react-icons/fa";

import { 
	ContactSection,
	MapSection,
	ContactUsSection,
	TitleHeading,
	Paragraph,
	ContactWrapper,
	ContentDiv,
	Sections,
	Address,
	Link,
	ContactDetails,
	LogoSection,
	LogoImg,
	LogoTitle,
	SubTitle,
	ContactTitle,
	StyledUl,
	StyledList,
	SocialLinks,
} from './styled';

const Contact = () => {
  	return (
    	<ContactSection id="contact">
			<MapSection />
			<ContactUsSection>
				<TitleHeading>Contact <span>Us</span></TitleHeading>
				<Paragraph>Get in touch with us, or reach us via email, phone number or visit to our destination. To get the best IT services and consultation.</Paragraph>
				<ContactWrapper>
					<ContentDiv>
						<Sections>
							<TitleHeading><GrMap /></TitleHeading>
							<Address>Insta Object Office, Kingston, ON. <br /> Toronto, Canada.</Address>
						</Sections>
						<Sections>
							<TitleHeading><FiPhoneCall /></TitleHeading>
							<Link href='tel:416123456'>(416) 123 456</Link>
						</Sections>
						<Sections>
							<TitleHeading><RiMailSendLine /></TitleHeading>
							<Link href='mailto:info@instaobjects.com'>info@instaobjects.com</Link>
						</Sections>
					</ContentDiv>
				</ContactWrapper>
			</ContactUsSection>
			<ContactDetails>
				<ContentDiv>
					<Sections>
            			<LogoSection>
							<LogoImg src={logo} alt="InstaObjects" />
							<LogoTitle>Insta<span>Objects</span></LogoTitle>
						</LogoSection>
						<SubTitle>IT Services and Consultation Agency.</SubTitle>
					</Sections>
					<Sections>
						<ContactTitle>Quick Links</ContactTitle>
						<StyledUl>
							<StyledList>
								<Link href='#' target='_blank'><IoIosArrowRoundForward /> Terms and Conditions</Link>
							</StyledList>
							<StyledList>
								<Link href='#' target='_blank'><IoIosArrowRoundForward /> Privacy Policy</Link>
							</StyledList>
							<StyledList>
								<Link href='career' target='_blank'><IoIosArrowRoundForward /> Careers</Link>
							</StyledList>
							<StyledList>
								<Link href='#' target='_blank'><IoIosArrowRoundForward /> FAQs</Link>
							</StyledList>
						</StyledUl>
					</Sections>
					<Sections className='socialDiv'>
						<ContactTitle>Follow Us On</ContactTitle>
						<SocialLinks>
							<Link href='#' target='_blank'>
								<FaLinkedin />
							</Link>
							<Link href='#' target='_blank'>
								<FaFacebookSquare />
							</Link>
							<Link href='#' target='_blank'>
								<FaGooglePlusSquare />
							</Link>
						</SocialLinks>
					</Sections>
				</ContentDiv>
			</ContactDetails>
    	</ContactSection>
  	)
}

export default Contact;