import React from 'react';

import { data } from './about-data.js';

import {
    AboutSection,
	AboutWrapper,
	ImageSection,
	ContentSection,
	TitleHeading,
	AboutThumbnail,
	Cards,
	Title,
	Details,
	Icon,
} from './styled';

const About = () => {
  return (
    <AboutSection id='about'>
        <AboutWrapper>
			<ContentSection>
				<TitleHeading>About <span>InstaObjects</span></TitleHeading>
				<AboutThumbnail>
				{
					data.map((list) => (
						<Cards key={list.id}>
							<Title>{list.title}</Title>
							<Details>{list.details}</Details>
							<Icon>{list.icon}</Icon>
						</Cards>
					))
				}
				</AboutThumbnail>
			</ContentSection>
			<ImageSection />
        </AboutWrapper>
    </AboutSection>
  )
}

export default About;