import React from 'react';

import { data } from './services-list.js';

import {
    ServicesSection,
	TitleHeading,
	SubTitle,
	ServicesWrapper,
	ServicesCards,
	CardContent,
	ServicesIcon,
	ServicesTitle,
} from './styled';


const Services = () => {
  return (
    <ServicesSection id='services'>
        <TitleHeading>Services & <span>Solutions</span></TitleHeading>
		<SubTitle>We have extensive technical knowledge and experience to deliver on specific client needs. Our Services cover the following areas:</SubTitle>
		<ServicesWrapper>
			{
				data.map((list)=> (
					<ServicesCards key={list.id} className={list.class}>
						<CardContent>
							<ServicesIcon>{list.icon}</ServicesIcon>
							<ServicesTitle>{list.title}</ServicesTitle>
						</CardContent>
					</ServicesCards>
				))
			}
		</ServicesWrapper>
    </ServicesSection>
  )
}

export default Services;