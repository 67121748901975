import React from 'react';
import CountUp from 'react-countup';

import {
    StatisticsSection,
    StatisticsWrapper,
    ContentDiv,
    TitleHeading,
    StatisticsSets,
    StatesDiv,
    MainFigure,
    SubTitle,
} from './styled';

const Statistics = () => {
  return (
    <StatisticsSection>
        <StatisticsWrapper>
            <TitleHeading><span>Technical</span> Statistics</TitleHeading>
            <StatisticsSets>
                <StatesDiv>
                    <ContentDiv>
                        <MainFigure>
                            <CountUp delay={1} end={20} duration={2} enableScrollSpy={true} />
                        </MainFigure>
                        <SubTitle>Years of Experience</SubTitle>
                    </ContentDiv>
                </StatesDiv>
                <StatesDiv>
                    <ContentDiv>
                        <MainFigure>
                            <CountUp delay={1} end={500} duration={4} enableScrollSpy={true} />
                        </MainFigure>
                        <SubTitle>Projects Delivered</SubTitle>
                    </ContentDiv>
                </StatesDiv>
                <StatesDiv>
                    <ContentDiv>
                        <MainFigure>
                            <CountUp delay={1} end={400} duration={4} enableScrollSpy={true} />
                        </MainFigure>
                        <SubTitle>Satisfied Customers</SubTitle>
                    </ContentDiv>
                </StatesDiv>
            </StatisticsSets>
        </StatisticsWrapper>
    </StatisticsSection>
  )
}

export default Statistics;