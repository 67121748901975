import styled from "styled-components";

export const NavbarSection = styled.div`
    background-color: var(--basic);
    box-sizing: border-box;
    box-shadow: 0 0 5px 2px var(--primary);
    display: flex;
    height: 90px;
    justify-content: space-between;
    left: 0;
    padding: 16px 32px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
`;

export const NavbarLogo = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 250px;
`;

export const LogoImg = styled.img`
    width: 60px;
`;

export const LogoTitle = styled.h5`
    span {
        color: var(--secondary);
        font-family: inherit;
        font-size: inherit;
        font-weight: 700;
    }
`;

export const NavbarMenu = styled.div`
    width: 650px;
`;

export const StyledUl = styled.ul`
    align-items: center;
    display: flex;
    height: 56px;
    justify-content: space-around;
    list-style-type: none;
`;

export const StyledLi = styled.li``;

export const Link = styled.a`
    color: var(--default);
    font-family: 'Open Sans', sans-serif;
    font-size: var(--large-size);
    font-weight: 500;
    letter-spacing: .2px;
    &:hover, &.active {
        color: var(--secondary);
    }
`;
