import styled from "styled-components";

export const Button = styled.a`
    align-items: center;
    background-color: var(--basic);
    border-radius: 5px;
    border: 2px solid var(--basic);
    color: var(--secondary);
    cursor: pointer;
    display: inline-flex;
    font-size: var(--large-size);
    justify-content: center;
    margin: 0 auto;
    padding: 12px 20px;
    svg {
        font-size: var(--largest-size);
        margin-left: 8px;
        color: inherit;
        path {
            color: inherit;
        }
    }
    &:hover {
        background-color: transparent;
        color: var(--basic);
    }
`;
