import userImage from './../../images/user-img.png';

export const feedback = [
    {
        id: 1,
        image: userImage,
        user: "Muhammad Faheem",
        designation: "CEO, XYZ Company",
        feedback: "This is the client feedback that should be written here. Client feedback for InstaObject. This is the client feedback that should be written here. Client feedback for InstaObject.",
    },
    {
        id: 2,
        image: userImage,
        user: "Adil Rehman",
        designation: "General Manager, KBOP",
        feedback: "This is the client feedback that should be written here. Client feedback for InstaObject. This is the client feedback that should be written here. Client feedback for InstaObject.",
    },
    {
        id: 3,
        image: userImage,
        user: "Usman Jaan",
        designation: "CEO, InstaObjects",
        feedback: "This is the client feedback that should be written here. Client feedback for InstaObject. This is the client feedback that should be written here. Client feedback for InstaObject.",
    },
]