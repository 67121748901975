import { MdOutlineScreenshotMonitor, MdOutlineDesignServices, MdManageAccounts, MdOutlineDeveloperMode, MdPermDeviceInformation } from "react-icons/md";
import { IoHardwareChipOutline, IoHelpCircleOutline, IoCloudDoneOutline } from "react-icons/io5";
import { FaRegHandshake, FaMapMarkedAlt, FaNetworkWired } from "react-icons/fa";
import { LiaBusinessTimeSolid, LiaProjectDiagramSolid } from "react-icons/lia";
import { TbBusinessplan, TbDeviceImacCog, TbTransfer } from "react-icons/tb";
import { GrSystem, GrTechnology } from "react-icons/gr";
import { PiListMagnifyingGlass } from "react-icons/pi";
import { HiCodeBracket } from "react-icons/hi2";
import { BsDatabase } from "react-icons/bs";
import { VscGraph } from "react-icons/vsc";
import { CiInboxOut } from "react-icons/ci";
import { TfiWrite } from "react-icons/tfi";

export const data = [
    {
        id: 1,
        title: "Application Support",
        class: "border-left-top",
        icon: <MdOutlineScreenshotMonitor />,
    },
    {
        id: 2,
        title: "Architecture",
        class: "border-top",
        icon: <MdOutlineDesignServices />
    },
    {
        id: 3,
        title: "Business Analysis",
        class: "border-top",
        icon: <TbBusinessplan />
    },
    {
        id: 4,
        title: "Business Intelligence",
        class: "border-right-top",
        icon: <MdManageAccounts />
    },
    {
        id: 5,
        title: "Business Process Management",
        class: "border-left",
        icon: <LiaBusinessTimeSolid />
    },
    {
        id: 6,
        title: "Custom Applications development",
        class: "",
        icon: <MdOutlineDeveloperMode />
    },
    {
        id: 7,
        title: "Content/Training/Design",
        class: "",
        icon: <TfiWrite  />
    },
    {
        id: 8,
        title: "Content Management System",
        class: "border-right",
        icon: <TbDeviceImacCog />
    },
    {
        id: 9,
        title: "Database Administration",
        class: "border-left",
        icon: <BsDatabase />
    },
    {
        id: 10,
        title: "Design & Development",
        class: "",
        icon: <HiCodeBracket />
    },
    {
        id: 11,
        title: "ERP / CRM / ECM",
        class: "border-right",
        icon: <VscGraph />
    },
    {
        id: 12,
        title: "GIS",
        class: "border-right",
        icon: <FaMapMarkedAlt />
    },
    {
        id: 13,
        title: "Hardware / Firmware",
        class: "border-left",
        icon: <IoHardwareChipOutline />
    },
    {
        id: 14,
        title: "Help Desk",
        class: "",
        icon: <IoHelpCircleOutline />
    },
    {
        id: 15,
        title: "IT Management",
        class: "",
        icon: <MdPermDeviceInformation />
    },
    {
        id: 16,
        title: "Migration Solutions",
        class: "border-right",
        icon: <TbTransfer />
    },
    {
        id: 17,
        title: "Network and Infrastructure",
        class: "border-left",
        icon: <FaNetworkWired />
    },
    {
        id: 18,
        title: "Outsourcing IT Services",
        class: "",
        icon: <CiInboxOut />
    },
    {
        id: 19,
        title: "Outsourcing Management",
        class: "",
        icon: <FaRegHandshake />
    },
    {
        id: 20,
        title: "Project Management",
        class: "border-right",
        icon: <LiaProjectDiagramSolid />
    },
    {
        id: 21,
        title: "Quality Assurance",
        class: "border-left-bottom",
        icon: <PiListMagnifyingGlass />
    },
    {
        id: 22,
        title: "Software Development",
        class: "border-bottom",
        icon: <GrSystem />
    },
    {
        id: 23,
        title: "System Integration",
        class: "border-bottom",
        icon: <IoCloudDoneOutline />
    },
    {
        id: 24,
        title: "Technologies: Microsoft, Sun, Google, Apple",
        class: "border-right-bottom",
        icon: <GrTechnology />
    },
]