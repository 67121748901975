import styled from "styled-components";

import banner from './../../images/backgrounds/banner.jpg';

export const BannerSection = styled.section`
    background: url(${banner}) no-repeat center;
    background-size: cover;
    background-attachment: fixed;
    min-height: 450px;
    position: relative;
`;

export const BannerWrapper = styled.div`
    align-items: center;
    background-color: rgba(79, 129, 188, .6);
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    padding: 40px 100px;
    position: absolute;
    top: 0;
    width: 100%;
`;

export const ContentDiv = styled.div`
    text-align: center;
`;

export const SubTitle = styled.h4`
    font-weight: 300;
    text-shadow: 1px 1px var(--dark);
    text-align: center;
    line-height: 56px;
    margin-bottom: 40px;
    width: 1400px;
    span {
        background-color: var(--secondary);
        border-radius: 5px;
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        font-weight: 600;
        display: inline-block;
        padding: 0 8px;
        text-shadow: 1px 1px var(--dark);
    }
`;
