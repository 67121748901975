import React from 'react';

import { IoIosArrowRoundForward } from 'react-icons/io';

import {
    BannerSection,
    BannerWrapper,
    ContentDiv,
    SubTitle,
} from './styled';


import Buttons from './../Buttons';


const Banners = () => {
  return (
    <BannerSection>
        <BannerWrapper>
            <ContentDiv>
            <SubTitle>
                Let us help you in managing and achieving your IT Goals and services more efficiently. <span>Contact Us</span> Today!!!
            </SubTitle>
            <Buttons
				target="#contact" 
                title="Contact Us" 
				icon={<IoIosArrowRoundForward />}
            />
            </ContentDiv>
        </BannerWrapper>
    </BannerSection>
  )
}

export default Banners;