import styled from "styled-components";
import aboutImg from "./../../images/backgrounds/laptop-side.jpg";

export const AboutSection = styled.section`
    background-color: var(--light);
    box-sizing: border-box;
    min-height: 100vh;
    padding: 120px 100px;
    position: relative;
`;

export const AboutWrapper = styled.div`
    display: flex;
`;

export const ContentSection = styled.div`
    max-width: 920px;
    position: relative;
    z-index: 9;
`;

export const TitleHeading = styled.h3`
    margin-bottom: 60px;
    width: 400px;
    span {
        color: var(--secondary);
        font-family: inherit;
        font-size: calc(var(--section-heading) + 10px);
        font-weight: 800;
    }
`;

export const Paragraph = styled.p`
    color: var(--mindcolor);
    text-align: justify;
`;

export const AboutThumbnail = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 40px 20px;
    justify-content: space-between;
`;

export const Cards = styled.div`
    background-color: var(--primary);
    border-radius: 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 48%;
    min-height: 260px;
    min-width: 48%;
    padding: 20px 50px;
    position: relative;
    transition: all .2s ease-in-out;
    &:hover {
        background-color: var(--secondary);
        * {
            color: var(--dark);
            font-weight: 500;
        }
        > div {
            filter: brightness(110%);
            svg path{
                color: var(--secondary);
            }
        }
    }
    &:last-child {
        align-items: center;
        background-color: var(--secondary);
        border-radius: 120px;
        display: flex;
        flex-direction: row;
        height: 200px;
        * {
            color: var(--dark);
            font-size: var(--large-size);
            font-style: italic;
            font-weight: 400;
            margin-bottom: 0;
        }
        &:hover {
            transform: scale(1.1);
        }
    }
`;

export const Title = styled.h4`
    font-weight: 400;
    z-index: 2;
`;

export const Details = styled.p`
    color: var(--light);
    line-height: 22px;
    text-align: justify;
    letter-spacing: .25px;
    margin-bottom: 8px;
    z-index: 2;
`;

export const Icon = styled.div`
    filter: brightness(120%);
    position: absolute;
    right: 4px;
    top: 12px;
    svg {
        font-size: calc(var(--main-heading) * 2);
        path {
        }
    }
`;

export const ImageSection = styled.div`
    background: url(${aboutImg}) no-repeat;
    background-size: contain;
    height: 590px;
    position: absolute;
    right: 0;
    top: 25%;
    transform: scaleX(-1);
    width: 520px;
`;
