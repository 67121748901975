import styled from "styled-components";

import mapImage from './../../images/backgrounds/map.png';

export const ContactSection = styled.section`
    background-color: var(--secondary);
    min-height: 100vh;
`;

export const MapSection = styled.div`
    background: url(${mapImage}) no-repeat center;
    background-size: cover;
    height: 600px;
`;

export const ContactUsSection = styled.div`
    background-color: var(--basic);
    border-radius: 8px;
    box-shadow: 0 0 10px 0 var(--mindcolor);
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: -250px;
    min-height: 450px;
    padding: 60px 80px;
    width: 1400px;
`;

export const TitleHeading = styled.h3`
    color: var(--default);
    margin-bottom: 12px;
    text-align: center;
    span {
        color: var(--secondary);
        font-size: var(--title-heading);
    }
`;

export const Paragraph = styled.p`
    color: var(--mindcolor);
    font-size: var(--large-size);
    font-weight: 300;
    text-align: center;
    text-transform: capitalize;
`;

export const ContactWrapper = styled.div`
    margin-top: 48px;
`;

export const ContentDiv = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
`;

export const Sections = styled.div`
    flex: 1;
    padding: 24px 32px;
    &:nth-child(2) {
        border-left: 1px solid var(--light);
        border-right: 1px solid var(--light);
    }
    svg {
        font-size: inherit;
        path {
            color: var(--secondary);
        }
    }
    h3 {
        margin-bottom: 32px;
    }
`;


export const Address = styled.div`
    color: var(--primary);
    font-size: var(--large-size);
`;

export const Link = styled.a`
    color: var(--primary);
    font-size: var(--large-size);
    &:hover {
        text-decoration: underline;
    }
`;

export const ContactDetails = styled.div`
    background-color: var(--secondary);
    min-height: 300px;
    width: 1400px;
    margin: 0 auto;
    padding-top: 80px;
`;

export const LogoSection = styled.div`
    align-items: center;
    display: flex;
    margin-bottom: 16px;
`;

export const LogoImg = styled.img`
    border-radius: 4px;
    width: 50px;
`;

export const LogoTitle = styled.h4`
    color: var(--default);
    font-weight: 500;
    margin-left: 12px;
    span {
        color: var(--basic);
        font-family: inherit;
        font-size: inherit;
        font-weight: 800;
        text-shadow: 1px 1px var(--dark);
    }
`;

export const SubTitle = styled.h6`
    color: var(--dark);
    text-align: left;
`;

export const ContactTitle = styled.h6`
    color: var(--basic);
    font-weight: 800;
    margin-bottom: 20px;
    text-align: left;
    text-shadow: 1px 1px var(--dark);
    text-transform: uppercase;
`;

export const StyledUl = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: left;
`;

export const StyledList = styled.li`
    line-height: 28px;
    a {
        color: var(--dark);
        font-size: var(--default-size);
        transition: all .3s ease-in-out;
        svg {
            margin-right: 4px;
            path {
                color: var(--dark);
            }
        }
        &:hover {
            margin-left: 10px;
        }
    }
`;

export const SocialLinks = styled.div`
    display: flex;
    text-align: left;
    width: 150px;
    justify-content: space-between;
    a {
        display: block;
        font-size: var(--subtitle);
        transition: all .3s ease-in-out;
        svg path {
            color: var(--basic);
        }
        &:hover {
            margin-bottom: 5px;
            svg path {
                color: var(--default);
            }
        }
    }
`;
