import React from 'react';

import { Button } from './styled';

const Buttons = ({target, title, icon}) => {
  	return (
    	<Button href={target}>
			{title} {icon}
    	</Button>
  	)
}

export default Buttons;